import { createContext, type ReactNode, useContext, useMemo } from 'react';

import {
  type ConfirmCancelModalConfigurator,
  useCancelConfirmModalStateRoot,
} from './useCancelConfirmModalStateRoot';

type ConfirmCancelModalAPI = {
  awaitConfirmCancelModal: ConfirmCancelModalConfigurator;
  modal: JSX.Element | null;
};

const modalContext = createContext<ConfirmCancelModalAPI | null>(null);

export function ConfirmCancelModalProvider(props: {
  children?: ReactNode;
}): JSX.Element {
  const [modal, awaitConfirmCancelModal] = useCancelConfirmModalStateRoot();

  const contextValue = useMemo(
    () => ({ awaitConfirmCancelModal, modal }),
    [awaitConfirmCancelModal, modal]
  );

  return (
    <modalContext.Provider value={contextValue}>
      {props.children}
    </modalContext.Provider>
  );
}

export function ConfirmCancelModalRoot(): JSX.Element | null {
  const ctx = useContext(modalContext);
  return ctx?.modal ?? null;
}

/** Create an app-level Modal that covers the entire UI */
export function useAwaitFullScreenConfirmCancelModal(): ConfirmCancelModalConfigurator {
  const ctx = useContext(modalContext);
  if (!ctx) throw new Error('ModalProvider context not in tree');
  return ctx.awaitConfirmCancelModal;
}
