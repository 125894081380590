import {
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { assertExhaustive } from '../../utils/common';

export type ConfirmCancelModalConfig = {
  kind: 'confirm-cancel';
  prompt: JSX.Element;
  cancelBtnLabel?: string | JSX.Element;
  confirmBtnLabel: string | JSX.Element;
  confirmBtnClassName?: string;
  confirmBtnVariant?: 'delete' | 'primary' | 'warning';
  confirmBtnDataTestId?: string;
  cancelBtnClassName?: string;
  cancelBtnDataTestId?: string;
  autoFocus?: 'confirm' | 'cancel';
  containerClassName?: string;
  boxDimensionsClassName?: string;
  confirmOnly?: boolean;
};

export type CustomModalConfig = {
  kind: 'custom';
  element: (props: {
    internalOnConfirm: () => void;
    internalOnCancel: () => void;
  }) => JSX.Element;
  containerClassName?: string;
  noWrapper?: boolean;
};

export type ModalConfig = ConfirmCancelModalConfig | CustomModalConfig;

export type ConfirmCancelModalConfigurator = (
  config: ModalConfig
) => Promise<{ result: 'confirmed' | 'canceled' }>;

type Confirmation = {
  config: ModalConfig;
  internalOnConfirm: () => void;
  internalOnCancel: () => void;
};

function renderModal(confirmation: Confirmation): JSX.Element {
  switch (confirmation.config.kind) {
    case 'confirm-cancel':
      return (
        <PanelModal
          {...confirmation.config}
          onConfirm={confirmation.internalOnConfirm}
          onCancel={confirmation.internalOnCancel}
        />
      );
    case 'custom':
      return confirmation.config.noWrapper ? (
        confirmation.config.element(confirmation)
      ) : (
        <ConfirmCancelModalWrapper
          className={confirmation.config.containerClassName}
        >
          {confirmation.config.element(confirmation)}
        </ConfirmCancelModalWrapper>
      );
    default:
      assertExhaustive(confirmation.config);
      return <></>;
  }
}

/** Create a Modal that can cover the current component */
export function useCancelConfirmModalStateRoot(): readonly [
  JSX.Element | null,
  ConfirmCancelModalConfigurator
] {
  const [modalConfirmation, setModalConfirmation] = useState<
    null | Confirmation[]
  >(null);

  const newestConfirmation = modalConfirmation
    ? modalConfirmation[modalConfirmation.length - 1]
    : null;

  const confirmModal = !newestConfirmation
    ? null
    : renderModal(newestConfirmation);

  const awaitConfirmCancelModal: ConfirmCancelModalConfigurator = useCallback(
    (config) => {
      // An array is used to allow multiple modals to stack without conflicting.
      // If multiple calls happen, the modals will each continue to block the
      // page until they are all dismissed.

      const removeConfirmation = (
        others: Confirmation[] | null,
        toRemove: Confirmation
      ) => {
        const idx = others?.indexOf(toRemove) ?? -1;
        if (others && idx > -1) {
          others?.splice(idx, 1);
          return [...others];
        }
        return others;
      };

      return new Promise((resolve) => {
        setModalConfirmation((existing) => {
          const confirmation = {
            config,
            internalOnCancel: () => {
              resolve({ result: 'canceled' });
              setModalConfirmation((others) => {
                return removeConfirmation(others, confirmation);
              });
            },
            internalOnConfirm: () => {
              resolve({ result: 'confirmed' });
              setModalConfirmation((others) => {
                return removeConfirmation(others, confirmation);
              });
            },
          };

          return [...(existing ?? []), confirmation];
        });
      });
    },
    []
  );

  return [confirmModal, awaitConfirmCancelModal] as const;
}

/**
 * Only use this if you are building your own custom modal.
 */
export const ConfirmCancelModalWrapper = (props: {
  className?: string;
  children: ReactNode;
}) => {
  return (
    <div
      className={`absolute top-0 left-0 w-full h-full z-50 flex 
      items-center justify-center p-2 overflow-auto scrollbar ${
        props.className ? props.className : 'bg-lp-black-004'
      }`}
    >
      {props.children}
    </div>
  );
};

export function ConfirmCancelModalText(props: {
  className?: string;
  children: JSX.Element | string;
}): JSX.Element {
  return (
    <p
      className={`text-white text-base text-center font-bold ${
        props.className ?? ''
      }`}
    >
      {props.children}
    </p>
  );
}

export function ConfirmCancelModalHeading(props: {
  className?: string;
  children: JSX.Element | string;
}): JSX.Element {
  return (
    <p
      className={`text-white text-2xl font-medium text-center ${
        props.className ?? ''
      }`}
    >
      {props.children}
    </p>
  );
}

export function ConfirmCancelModalAlertHeading(props: {
  className?: string;
  children: JSX.Element | string;
}): JSX.Element {
  return (
    <p className={`text-red-002 text-2xl font-medium ${props.className}`}>
      {props.children}
    </p>
  );
}

export function ConfirmCancelModalBox(props: {
  className?: string;
  children: ReactNode;
  borderVariant?: 'white' | 'black';
}): JSX.Element {
  return (
    <div
      className={`
        border border-secondary bg-black rounded-xl
        ${props.className ?? 'px-3 pt-3 max-w-85 min-h-45'}
        flex flex-col gap-3 justify-between
      `}
    >
      {props.children}
    </div>
  );
}

const PanelModal = (
  props: {
    onCancel: () => void;
    onConfirm: () => void;
  } & ConfirmCancelModalConfig
): JSX.Element => {
  // TODO: allow `onConfirm` to be null and only present onCancel as "Ok"-equivalent
  const confirmBtnRef = useRef<HTMLButtonElement | null>(null);
  const cancelBtnRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    props.autoFocus === 'confirm' && confirmBtnRef.current?.focus();
    props.autoFocus === 'cancel' && cancelBtnRef.current?.focus();
  }, [props.autoFocus]);

  return (
    <ConfirmCancelModalWrapper className={props.containerClassName}>
      <ConfirmCancelModalBox className={props.boxDimensionsClassName}>
        <div className='flex-grow flex flex-col justify-center items-center'>
          {props.prompt}
        </div>
        <div className='flex-grow-0 pb-3 flex gap-3 justify-center items-center'>
          {!props.confirmOnly && (
            <button
              type='button'
              ref={cancelBtnRef}
              className={`btn-secondary ${
                props.cancelBtnClassName ?? 'w-1/2 h-10'
              }`}
              onClick={props.onCancel}
              data-testid={props.cancelBtnDataTestId}
            >
              {props.cancelBtnLabel ?? 'Cancel'}
            </button>
          )}
          <button
            type='button'
            ref={confirmBtnRef}
            className={`btn-${props.confirmBtnVariant || 'primary'}
              ${props.confirmBtnClassName ?? 'w-1/2 h-10'}
            `}
            onClick={props.onConfirm}
            data-testid={props.confirmBtnDataTestId}
          >
            {props.confirmBtnLabel}
          </button>
        </div>
      </ConfirmCancelModalBox>
    </ConfirmCancelModalWrapper>
  );
};
